<template>
  <section>
    <div class="content-header">
      <h2>Marginalized identities and intersectionality</h2>
    </div>
    <div class="content-wrapper">
      <p>Throughout this section of the toolkit, we use the term “marginalized identities” to refer to disadvantaged sociodemographic groups that include:</p>
      <ul>
        <li class="pb-2">Sexual minorities (students who do not identify as heterosexual)</li>
        <li class="pb-2">Students who identify as transgender or gender non-binary</li>
        <li class="pb-2">Female-identified individuals</li>
        <li class="pb-2">Racial and ethnic minorities (students who identify as non-white)</li>
        <li class="pb-2">Indigenous students</li>
        <li class="pb-2">Students with disabilities </li>
        <li>Students with diagnosed or unidentified psychiatric conditions</li>
      </ul>
      <p>In this clip from the webinar, “Supporting Mental Health and Learning among Students with Marginalized Identities”, Rick Ezekiel offers a more detailed explanation of why we say “marginalized identities”, and introduces the concept of intersectionality.</p>
      <p>Used with permission from Rick Ezekiel.</p>
      <div class="video-player">
        <!-- video element -->
        <div class="ratio ratio-16x9">
  <iframe
    src="https://www.youtube.com/embed/wmMaYQchnGU?rel=0&cc_load_policy=1"
    title="YouTube video"
    allowfullscreen
  ></iframe>
</div>
        <!-- <vue-plyr>
          <video
            controls
            crossorigin
            playsinline
          >
            <source
              size="720"
              src="https://staging.gevc.ca/centennial/tic/videos/community/learn/06.mp4"
              type="video/mp4"
            />
            <track
              label="English"
              kind="subtitles"
              srclang="en"
              src="https://staging.gevc.ca/centennial/tic/cc/community/learn/05.en.vtt"
              default
            >
          </video>
        </vue-plyr> -->
        <div class="accordion">
          <accordion
            headerText="Transcript"
            accordionID="transcript01"
          >
            <p>So, moving into talking about the focus area of this slide. I want to start by clarifying a little bit what we mean by marginalized identities. And I'll also note that, you know, I selected certain types of language in this presentation and in my work . I'll always endeavor to operationalize it and share with you exactly what I mean, how I'm defining that word, while recognizing there are lots of ways to describe this. So, you know, we often hear the language of equity-seeking groups. We hear marginalized identities, marginalized socio-demographic groups, et cetera, et cetera. I've chosen the language “marginalized” because I really want to position the marginalization is being done to right? Not being driven by the identity or the human behind that identity. So really thinking about the systems and structures that are driving that experience of being othered, of being marginalized of being separate from the mainstream or the dominant norm, which, of course, has deep impacts on development, on health outcomes, on mental health outcomes and on learning.</p>
            <p>So, specifically within my group or within my research, I've looked at a number of different marginalized and disadvantaged socio-demographic groups, which has included sexual minorities, students who don't identify as heterosexual, including, students who have identified as lesbian, gay, bisexual, queer, asexual. Students who identify as transgender or gender nonbinary, which would contrast with students who identify as cisgender, female identified individuals, racial and ethnic minorities, so really any racial or ethnic identity where students identified as non-white, Indigenous students, students with disabilities and students with diagnosed psychiatric condition.</p>
            <p>Again, I’ll identify here that it's really important that we complicate our understanding, and we'll do some of this within the context of the session, that none of these identities exist on a binary, that there there's a continuum of ways that people exist and identify. And similarly, many of them overlap. So we'll talk a little bit about intersectionality in a moment. But with that, it's sometimes helpful to use that binary analysis so we can start to find that disparities where are our systems and structures causing the problems? So, you will hear me using the binary language sometimes, of sort of the two constructed identities. And I do that to be able to identify mean differences. So where are we seeing differences in outcomes in the system? When we translate this work to an individual, we really need to recognize that those binaries don't apply, that that individual is complicated, that their identities exist on a continuum and our function of multiple intersecting identities that are in no way represented entirely by this list of course.</p>
            <p>So talking about intersectionality, the term intersectionality was first identified, conceptualized, coined, by Kimberlé Crenshaw, and it really talks about or tries to bring to bear the idea that systems that perpetuate inequality, interact and compound when individuals hold multiple marginalized identities, and this leads to the creation of experiences of marginalization and oppression beyond that, which is additive across two binaries alone. In other words, the whole is greater than the sum of its parts. And this concept really came forward to nudge us when we're engaging in equity work, when we're supporting equity movements to not do that on a single binary. Right? I can use right now the context of thinking about pride and queer rights, which very much has become an environment and atmosphere that's supportive to folks who might look and identify in many of the ways that I do, of, you know, gay white men who are cis-gender, in absence of an analysis of what role does race play in influencing the queer experience? What role does colonization play? What role does gender identity play? What role does the diversity of gender identities play? And who's really experiencing the brunt of marginalization, highest levels of violence within a given community. What role does class and socioeconomic status play? Right? It comes back to sort of, again, that multilevel, ecological systems analysis of who we are and how we develop as humans. So it nudges us to think beyond ourselves and outside of ourselves, who might be experiencing different sets of marginalization than us? Who might be experiencing more risks, potentially, of, experiences of marginalization and prejudice as a result of holding multiple marginalized identities?</p>
          </accordion>
        </div>
        <p><a :href="`${publicPath}docs/community/Marginalized.pdf`" target="_blank">Open transcript in a new tab</a></p>
      </div>

    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
